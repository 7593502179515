import request from '@/common/utils/request'
import Vue from "vue";

export function getYqOneList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/yqOne',
    method: 'post',
    data
  })
}
