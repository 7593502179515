<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input v-model="searchForm.name" size="mini" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button  size="mini" type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  size="mini" type="primary" @click="clear">清空</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="primary" size="mini" @click="exportLogExcel">按搜索条件导出Excel</el-button>
            </el-form-item> -->
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      ref="elTable"
      :header-cell-style="{backgroundColor:'#f4f4f5',textAlign:'center'}"
      :height="tableHeight"
      border
      style="width: 100%">
      <el-table-column
        type="selection"
        align="center"
        width="55">
      </el-table-column>
      <el-table-column

        align="center"
        prop="id"
        label="id"
        width="80">
      </el-table-column>
      <el-table-column

        align="center"
        prop="name"
        label="姓名"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="address"
        label="地址"
        width="250">
      </el-table-column>
      <!-- <el-table-column

        align="center"
        prop="sq"
        label="21天内社区是否发生疫情"
        width="180">
        <template slot-scope="scope">
          {{ judgeDate(scope.row.sq) }}
        </template>
      </el-table-column> -->
      <el-table-column

        align="center"
        prop="ma"
        label="健康码颜色"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="zh"
        label="症状(传染病)"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="szh"
        label="症状"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="gl"
        label="是否解除隔离"
        width="100">
        <template slot-scope="scope">
          {{ judgeDate2(scope.row.gl) }}
        </template>
      </el-table-column>
      <el-table-column

        align="center"
        prop="hs"
        label="核酸检测"
        width="100">
        <template slot-scope="scope">
          {{ judgeDate1(scope.row.hs) }}
        </template>
      </el-table-column>
      <el-table-column

        align="center"
        prop="frontUserId"
        label="frontUserId"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="pc"
        label="如出现以上症状是否排除传染病"
        width="100">
        <template slot-scope="scope">
          {{ judgeDate3(scope.row.pc) }}
        </template>
      </el-table-column>
      <el-table-column

        align="center"
        prop="createTime"
        label="创建时间"
        width="100">
        <template slot-scope="scope">
          {{ fmtDate2(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column

        align="center"
        prop="pid"
        label="pid"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="ymStatus"
        label="接种情况"
        width="100">
        <template slot-scope="scope">
          {{ judgeDate4(scope.row.ymStatus) }}
        </template>
      </el-table-column>
      <el-table-column

        align="center"
        prop="tAddress"
        label="途径地"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="startAddress"
        label="出发地"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="jcs14"
        label="jcs14"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="jcs21"
        label="jcs21"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="jcs14Address"
        label="jcs14Address"
        width="100">
      </el-table-column>
      <el-table-column

        align="center"
        prop="jcs21Address"
        label="jcs21Address"
        width="100">
      </el-table-column>

    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import request from '@/common/utils/request.js'
import util from '@/common/utils/util.js'
import {getMyParticipateList} from "@/api/participate"
import {getYqOneList} from "../../api/yqOne";




export default {
  name: "yqOne",
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible:false,
      loading: false,
      loadingDiv: null,
      searchForm: {
        name: '',
      },
    }


  },
  watch:{

  },
  mounted() {

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight){
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
    })
    window.onresize = () => {
      this.$nextTick(() => {
          this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
      })
    }
  },
  methods: {
    clear(){
      for(let key in this.searchForm){
        this.searchForm[key]  = ''
      }
      this.getDataList(1);
    },
    fmtDate2(d2, fmt) { // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2)){
        return "";
      }
      return  this.fmtDate(new Date(d2*1000), fmt);
    },
    judgeDate(d2) {
      if (d2==-1){
        return "否";
      }
      return  "是";;
    },
    judgeDate1(d2) {
      if (d2==-1){
        return "阴性";
      }else if(d2==1){
        return "阳性";
      }
      return  "未做检测";;
    },
    judgeDate2(d2) {
      if (d2==1){
        return "否";
      }else if(d2==0){
        return "是";
      }
      return  "不属于医学隔离对象";
    },
    judgeDate3(d2) {
      if (d2==1){
        return "否";
      }else if(d2==2){
        return "是";
      }

    },
    judgeDate4(d2) {
      if (d2==1){
        return "接种中";
      }else if(d2==0){
        return "未接种";
      }
      return  "以接种";
    },
    fmtDate(d, fmt){ // yyyy-MM-dd hh:mm:ss
      if(!fmt){
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+" : d.getMonth()+1,                 //月份
        "d+" : d.getDate(),                    //日
        "h+" : d.getHours(),                   //小时
        "m+" : d.getMinutes(),                 //分
        "s+" : d.getSeconds(),                 //秒
        "q+" : Math.floor((d.getMonth()+3)/3), //季度
        "S"  : d.getMilliseconds()             //毫秒
      };
      if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (d.getFullYear()+"").substr(4 - RegExp.$1.length));
      }
      for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
      }
      return fmt;
    },
    getDataList(pageNum){
      console.log(pageNum);
      const loadingDiv = util.showLoading()

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      }
      console.log(this.searchForm)
      for (let key in this.searchForm) {
        console.log(this.searchForm[key])
        param[key] = this.searchForm[key];
      }

    getYqOneList(param).then(res => {
      console.log(res)
        if(res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }else if(res.code == 1) {
          let data = res.data;
          this.currentPage = pageNum;
          this.total = data.total;
          this.tableData = data.dataList;
        }
        loadingDiv.close();
      }).catch(res => {
        loadingDiv.close();
      })
    },
    search() {
      this.getDataList(1)
    },
    handleCurrentChange(pageNum){
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize;
      this.getDataList(1)
    }
  }
}
</script>

<style scoped lang="scss">

  // @import "~@/common/styles/element-variables.scss";
  .search-box {
    background-color: #FFFFFF;
    min-height: 36px;
    line-height: 36px;
    padding-top: 2px;
    padding-bottom: 6px;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .el-form--inline {
      display: inline-block;
    }
  }
  .pagination-box {
    text-align: left;
    margin-top: 10px;
  }
</style>
<style lang="scss">
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-dialog__header {

  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .el-dialog__footer {
    padding-top: 0px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-tabs--border-card {
    box-shadow: none;
  }
</style>
